<template>
    <div>
        <!--<ul class="nav nav-tabs"
            id="myTab"
            role="tablist">
            <li v-for="(year, index) in this.getYears"
                class="nav-item"
                role="presentation">
                <button class="nav-link"
                    :class="index == 0 ? 'active': ''"
                    :id="getYearButtonId(year)"
                    data-bs-toggle="tab"
                    :data-bs-target="getTarget(year)"
                    type="button"
                    role="tab"
                    :aria-controls="getTabId(year)"
                    :aria-selected="index == 0 ? true : false">{{year}}</button>
            </li>

        </ul>-->
        <div class="tab-content"
            id="priceTabContent">

            <table class="table pricelist-table table-hover">

                <tbody v-for="sg in priceArray">
                    <tr :class="{'mainrow': sg.lines && sg.lines.length > 0}"
                        v-if="sg.service.type == 'RENT' && sg.nightRange && sg.nightRange.min > 5">
                        <td>
                            {{getServiceDescription()}}
                            <small>
                                   <!--<span v-if="sg.nightRange">
                                     <span v-if="sg.nightRange.max < 99">
  														{{sg.nightRange.min}} - {{sg.nightRange.max}} 
                                       <span v-if="sg.nightRange.max > 1">{{voMsg('tpl.text.nights')}}</span>
                                       <span v-if="sg.nightRange.max == 1">{{voMsg('tpl.text.night')}}</span>
  												 </span> 
                                      <span v-if="sg.nightRange.max > 99">
  														 {{voMsg('tpl.startfrom')}} {{sg.nightRange.min}} {{voMsg('tpl.text.nights')}}
  												 </span> 
  												</span>-->
                                    
                                  <span v-if="sg.guestRange">
                                      <span v-if="sg.guestRange.max > 99">
                                         {{voMsg('tpl.startfrom')}} {{sg.guestRange.min}} {{voMsg('tpl.text.persons')}}
  													</span>
                                      <span v-if="sg.guestRange.max < 99">
                                        {{sg.guestRange.min}} - {{sg.guestRange.min}} {{voMsg('tpl.text.persons')}}
  													</span>
  												</span>
											</small>
                            <span v-if="! sg.service.mandatory">({{voMsg('tpl.text.optional')}})</span>
                            <span v-if="sg.service.mandatory"><sup>*</sup></span>
                        </td>

                        <td class="text-end">
                            <div v-if="sg.service.type != 'RENT'">
                                <span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                                <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                                <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                                <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                                <span v-if="sg.service.perChild"><span>{{voMsg('tpl.perChild')}}</span></span>
                                <span v-if="sg.service.perAdult"><span>{{voMsg('tpl.perAdult')}}</span></span>
                            </div>
                        </td>
                        <td class="text-end price">
                            <span class="text-nowrap"
                                v-if="sg.price">{{getCalculatedPrice(sg.price)}}</span>
                            <span class="text-nowrap"
                                v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>

                        </td>

                    </tr>
                    <tr v-for="price in sg.lines"
                        class="subrow"
                        v-if=" sg.service.type == 'RENT' && sg.nightRange && sg.nightRange.min > 5">
                        <td class="subinfo">
                            <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                            <br/><small>{{price.season}}</small>
                        </td>

                        <td class="text-end calc">
                            <span v-if="sg.service.type != 'RENT'">
                              	<span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                            <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                            <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                            <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                            <span v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </span>
                            <!--<span v-if="sg.service.type == 'RENT'">
                                  {{voMsg('tpl.service.WEEK')}}
  											</span>-->
                        </td>
                        <td class="text-end price">
                            <span class="text-nowrap"
                                v-if="price.value">{{getSumPrice(price.value)}}</span>
                            <span class="text-nowrap"
                                v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                        </td>
                    </tr>

                </tbody>
                <tbody v-for="sg in priceArray">
                    <tr :class="{'mainrow': sg.lines && sg.lines.length > 0}"
                        v-if="sg.service.type == 'VTAX'">
                        <td>
                            {{voMsg("price.tax.descirption")}}
                            <small>
                                   <!--<span v-if="sg.nightRange">
                                     <span v-if="sg.nightRange.max < 99">
  														{{sg.nightRange.min}} - {{sg.nightRange.max}} 
                                       <span v-if="sg.nightRange.max > 1">{{voMsg('tpl.text.nights')}}</span>
                                       <span v-if="sg.nightRange.max == 1">{{voMsg('tpl.text.night')}}</span>
  												 </span> 
                                      <span v-if="sg.nightRange.max > 99">
  														 {{voMsg('tpl.startfrom')}} {{sg.nightRange.min}} {{voMsg('tpl.text.nights')}}
  												 </span> 
  												</span> -->
                                    
                                  <span v-if="sg.guestRange">
                                      <span v-if="sg.guestRange.max > 99">
                                         {{voMsg('tpl.startfrom')}} {{sg.guestRange.min}} {{voMsg('tpl.text.persons')}}
  													</span>
                                      <span v-if="sg.guestRange.max < 99">
                                        {{sg.guestRange.min}} - {{sg.guestRange.min}} {{voMsg('tpl.text.persons')}}
  													</span>
  												</span>
											</small>
                            <span v-if="! sg.service.mandatory">({{voMsg('tpl.text.optional')}})</span>
                            <span v-if="sg.service.mandatory"><sup>*</sup></span>
                        </td>

                        <td class="text-end">
                            <!--<div v-if="sg.service.type != 'RENT'">
                                    <span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                                    <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                                    <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                                    <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                                    <span v-if="sg.service.perChild"><span>{{voMsg('tpl.perChild')}}</span></span>
                                    <span v-if="sg.service.perAdult"><span>{{voMsg('tpl.perAdult')}}</span></span>
                                </div>-->
                        </td>
                        <td class="text-end price">
                            <span class="text-nowrap"
                                v-if="sg.price">{{getCalculatedPrice(sg.price)}}</span>
                            <span class="text-nowrap"
                                v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            <span v-if="sg.service.type == 'RENT'">
                                  {{voMsg('price.title')}}                                 
                                		<!--Preis pro Woche für 2 Personen <br/>- inklusive Miete, Buchungsgebühr, Wäsche, <br/>Endreinigung, Heizkosten, exklusive Kurtaxe-->
  											</span>
                        </td>

                    </tr>
                    <tr v-for="price in sg.lines"
                        class="subrow"
                        v-if=" sg.service.type == 'VTAX'">
                        <td class="subinfo">
                            <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                            <br/><small>{{price.season}}</small>
                        </td>

                        <td class="text-end calc">
                            <span v-if="sg.service.type != 'RENT'">
                              	<span v-if="sg.service.calculation === 'FLAT'">{{voMsg('tpl.service.FLAT')}}</span>
                            <span v-if="sg.service.calculation === 'NIGHT'">{{voMsg('tpl.service.NIGHT')}}</span>
                            <span v-if="sg.service.calculation === 'WEEK'">{{voMsg('tpl.service.WEEK')}}</span>
                            <span v-if="sg.service.calculation === 'MONTH'">{{voMsg('tpl.service.MONTH')}}</span>
                            <span v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                            </span>
                            <!--<span v-if="sg.service.type == 'RENT'">
                                  {{voMsg('tpl.service.WEEK')}}
  											</span>-->
                        </td>
                        <td class="text-end price">
                            <span class="text-nowrap"
                                v-if="price.value">{{getSumPrice(price.value)}}</span>
                            <span class="text-nowrap"
                                v-if="sg.service.calculation === 'USAGE'">{{voMsg('tpl.service.USAGE')}}</span>
                        </td>
                    </tr>

                </tbody>
            </table>




        </div>

        <div class="hint"><sup>*</sup>{{voMsg('tpl.text.priceObligatory')}}</div>
        <!--<div class="hint">
            {{voMsg('price.title')}} - {{voMsg('price.description')}}
        </div>-->

    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'seasonprices',
    	props: {
    		pricelist: String
    	},
    	data: function() {
    		return {
    			priceArray: {},
    			years: Array,
    			unitId: Number,
    			priceBookingFee: Number, //53294
    			priceFinalCleaning1: Number, //53283 
    			priceFinalCleaning2: Number, //55626
    			laundry1: Number, //55452 
    			laundry2: Number, //55627
    			heatingFee: Number, //55628 
    			rentalPrices: Array,
    			mandatoryPrices: Array,
    			optionalPrices: Array,
    			surchargePrices: Array
    		};
    	},
    	mounted: function() {
    		var pricelist = JSON.parse(this.pricelist);
    		this.priceArray = pricelist;
    		//console.log('pricelist:::' + JSON.stringify(pricelist, 4));
    		var priceObj;
    		this.priceFinalCleaning1 = 0;
    		this.priceFinalCleaning2 = 0;
    		this.priceBookingFee = 0;
    		this.laundry1 = 0;
    		this.laundry2 = 0;
    		this.heatingFee = 0;
    		for (var i = 0; i < this.priceArray.length; i++) {
    			priceObj = this.priceArray[i];
    			//console.log('priceObj::' + priceObj.service._id);
    			//console.log('price::' + priceObj.price);
    			if (priceObj.service.type != 'RENT') {
    				if (priceObj.service._id == 53283) {
    					this.priceFinalCleaning1 = priceObj.price;
    				} else if (priceObj.service._id == 55626) {
    					this.priceFinalCleaning2 = priceObj.price;
    				} else if (priceObj.service._id == 53294) {
    					this.priceBookingFee = priceObj.price;
    				} else if (priceObj.service._id == 55452) {
    					this.laundry1 = (priceObj.price * 2);
    				} else if (priceObj.service._id == 55627) {
    					this.laundry2 = (priceObj.price * 2);
    				} else if (priceObj.service._id == 55628) {
    					this.heatingFee = priceObj.price * 8;
    				}
    			}
    		}
    		console.log
    	},
    	methods: {
    		getYearButtonId: function(year) {
    			return 't' + year + '-tab';
    		},
    		getTarget: function(year) {
    			return "#t" + year;
    		},
    		getTabId: function(year) {
    			return "t" + year;
    		},
    		getTabAreaLabel: function(year) {
    			return "t" + year + "-tab";
    		},
    		getFormattedDate: function(date) {
    			return DateEx.formatDate(date, 'dd.MM.yy', 'de');
    		},

    		getSumPrice: function(rentPrice) {
    			var totalPrice = 0;
    			var calcPrice = 0;
    			//console.log('rentPrice::' + rentPrice);
    			totalPrice = Number(rentPrice * 7);
    			if (this.priceFinalCleaning1 > 0) {
    				totalPrice += this.priceFinalCleaning1;
    			}
    			if (this.priceFinalCleaning2 > 0) {
    				totalPrice += this.priceFinalCleaning2;
    			}
    			if (this.priceBookingFee > 0) {
    				totalPrice += this.priceBookingFee;
    			}
    			if (this.laundry1 > 0) {
    				totalPrice += this.laundry1;
    			}
    			if (this.laundry2 > 0) {
    				totalPrice += this.laundry2;
    			}
    			if (this.heatingFee > 0) {
    				totalPrice += this.heatingFee;
    			}

    			calcPrice = Number(totalPrice / 100).toLocaleString("de-DE", {
    				minimumFractionDigits: 2
    			}) + " CHF";

    			return calcPrice;
    		},

    		getServiceDescription: function() {
    			var description = this.voMsg("price.description1");

    			if (this.priceBookingFee > 0) {
    				description += ", " + this.voMsg("price.description.bookingfee");
    			}

    			if (this.priceFinalCleaning1 > 0 || this.priceFinalCleaning2 > 0) {
    				description += ", " + this.voMsg("price.description.cleaningfee");
    			}
    			if (this.heatingFee > 0) {
    				description += ", " + this.voMsg("price.description.heatingfee");
    			}
    			if (this.laundry1 > 0 || this.laundry2 > 0) {
    				description += ", " + this.voMsg("price.description.laundry");
    			}
    			return description;
    		},

    		getCalculatedPrice: function(price, type = null) {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			if (price > 0) {
    				rentPrice = price;
    				if (type === 'RENT') {
    					calcPrice = Number((rentPrice * 7) / 100).toLocaleString("de-DE", {
    						minimumFractionDigits: 2
    					}) + " CHF";
    				} else {
    					calcPrice = Number((rentPrice) / 100).toLocaleString("de-DE", {
    						minimumFractionDigits: 2
    					}) + " CHF";
    				}
    			}
    			return calcPrice;
    		},
    		getCalculatedMandatoryPrice: function() {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			calcPrice = Number((this.priceBookingFee + this.priceFinalCleaning) / 100).toLocaleString("de-DE", {
    				minimumFractionDigits: 2
    			}) + " CHF";

    			return calcPrice;
    		},
    		isDateInRange: function(fromdate, year) {
    			var fromYear = parseInt(fromdate.substring(0, 4));
    			//console.log('fromYear::', fromYear, "Year::", year);
    			if (fromYear == year) {
    				return true;
    			}
    			return false;
    		}

    	},
    	computed: {
    		getYears: function() {
    			var pricelist = JSON.parse(this.pricelist);

    			var years = [];

    			var i, j;
    			for (i = 0; i < pricelist.length; i++) {
    				for (j = 0; j < pricelist[i].lines.length; j++) {
    					if (pricelist[i].lines[j].fromdate) {
    						var year = pricelist[i].lines[j].fromdate.substring(0, 4);
    						if (years.indexOf(year) < 0) {
    							years.push(year);
    						}
    					}
    				}
    			}
    			return years;
    		}
    	}
    };
</script>