var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tab-content", attrs: { id: "priceTabContent" } },
      [
        _c(
          "table",
          { staticClass: "table pricelist-table table-hover" },
          [
            _vm._l(_vm.priceArray, function(sg) {
              return _c(
                "tbody",
                [
                  sg.service.type == "RENT" &&
                  sg.nightRange &&
                  sg.nightRange.min > 5
                    ? _c(
                        "tr",
                        { class: { mainrow: sg.lines && sg.lines.length > 0 } },
                        [
                          _c("td", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.getServiceDescription()) +
                                "\n                            "
                            ),
                            _c("small", [
                              sg.guestRange
                                ? _c("span", [
                                    sg.guestRange.max > 99
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                         " +
                                              _vm._s(
                                                _vm.voMsg("tpl.startfrom")
                                              ) +
                                              " " +
                                              _vm._s(sg.guestRange.min) +
                                              " " +
                                              _vm._s(
                                                _vm.voMsg("tpl.text.persons")
                                              ) +
                                              "\n  \t\t\t\t\t\t\t\t\t\t\t\t\t"
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    sg.guestRange.max < 99
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(sg.guestRange.min) +
                                              " - " +
                                              _vm._s(sg.guestRange.min) +
                                              " " +
                                              _vm._s(
                                                _vm.voMsg("tpl.text.persons")
                                              ) +
                                              "\n  \t\t\t\t\t\t\t\t\t\t\t\t\t"
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            !sg.service.mandatory
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(_vm.voMsg("tpl.text.optional")) +
                                      ")"
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            sg.service.mandatory
                              ? _c("span", [_c("sup", [_vm._v("*")])])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end" }, [
                            sg.service.type != "RENT"
                              ? _c("div", [
                                  sg.service.calculation === "FLAT"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.FLAT"))
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.service.calculation === "NIGHT"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.NIGHT"))
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.service.calculation === "WEEK"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.WEEK"))
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.service.calculation === "MONTH"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.MONTH"))
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.service.perChild
                                    ? _c("span", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.voMsg("tpl.perChild"))
                                          )
                                        ])
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.service.perAdult
                                    ? _c("span", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.voMsg("tpl.perAdult"))
                                          )
                                        ])
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end price" }, [
                            sg.price
                              ? _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    _vm._s(_vm.getCalculatedPrice(sg.price))
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            sg.service.calculation === "USAGE"
                              ? _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v(_vm._s(_vm.voMsg("tpl.service.USAGE")))
                                ])
                              : _vm._e()
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(sg.lines, function(price) {
                    return sg.service.type == "RENT" &&
                      sg.nightRange &&
                      sg.nightRange.min > 5
                      ? _c("tr", { staticClass: "subrow" }, [
                          _c("td", { staticClass: "subinfo" }, [
                            price.fromdate
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getFormattedDate(price.fromdate)
                                    ) +
                                      " - " +
                                      _vm._s(
                                        _vm.getFormattedDate(price.tilldate)
                                      )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _c("small", [_vm._v(_vm._s(price.season))])
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end calc" }, [
                            sg.service.type != "RENT"
                              ? _c("span", [
                                  sg.service.calculation === "FLAT"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.FLAT"))
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.service.calculation === "NIGHT"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.NIGHT"))
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.service.calculation === "WEEK"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.WEEK"))
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.service.calculation === "MONTH"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.MONTH"))
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.service.calculation === "USAGE"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.USAGE"))
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end price" }, [
                            price.value
                              ? _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v(_vm._s(_vm.getSumPrice(price.value)))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            sg.service.calculation === "USAGE"
                              ? _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v(_vm._s(_vm.voMsg("tpl.service.USAGE")))
                                ])
                              : _vm._e()
                          ])
                        ])
                      : _vm._e()
                  })
                ],
                2
              )
            }),
            _vm._v(" "),
            _vm._l(_vm.priceArray, function(sg) {
              return _c(
                "tbody",
                [
                  sg.service.type == "VTAX"
                    ? _c(
                        "tr",
                        { class: { mainrow: sg.lines && sg.lines.length > 0 } },
                        [
                          _c("td", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.voMsg("price.tax.descirption")) +
                                "\n                            "
                            ),
                            _c("small", [
                              sg.guestRange
                                ? _c("span", [
                                    sg.guestRange.max > 99
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                         " +
                                              _vm._s(
                                                _vm.voMsg("tpl.startfrom")
                                              ) +
                                              " " +
                                              _vm._s(sg.guestRange.min) +
                                              " " +
                                              _vm._s(
                                                _vm.voMsg("tpl.text.persons")
                                              ) +
                                              "\n  \t\t\t\t\t\t\t\t\t\t\t\t\t"
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    sg.guestRange.max < 99
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(sg.guestRange.min) +
                                              " - " +
                                              _vm._s(sg.guestRange.min) +
                                              " " +
                                              _vm._s(
                                                _vm.voMsg("tpl.text.persons")
                                              ) +
                                              "\n  \t\t\t\t\t\t\t\t\t\t\t\t\t"
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            !sg.service.mandatory
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(_vm.voMsg("tpl.text.optional")) +
                                      ")"
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            sg.service.mandatory
                              ? _c("span", [_c("sup", [_vm._v("*")])])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end" }),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end price" }, [
                            sg.price
                              ? _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    _vm._s(_vm.getCalculatedPrice(sg.price))
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            sg.service.calculation === "USAGE"
                              ? _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v(_vm._s(_vm.voMsg("tpl.service.USAGE")))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            sg.service.type == "RENT"
                              ? _c("span", [
                                  _vm._v(
                                    "\n                                  " +
                                      _vm._s(_vm.voMsg("price.title")) +
                                      "                                 \n                                \t\t"
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(sg.lines, function(price) {
                    return sg.service.type == "VTAX"
                      ? _c("tr", { staticClass: "subrow" }, [
                          _c("td", { staticClass: "subinfo" }, [
                            price.fromdate
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getFormattedDate(price.fromdate)
                                    ) +
                                      " - " +
                                      _vm._s(
                                        _vm.getFormattedDate(price.tilldate)
                                      )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _c("small", [_vm._v(_vm._s(price.season))])
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end calc" }, [
                            sg.service.type != "RENT"
                              ? _c("span", [
                                  sg.service.calculation === "FLAT"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.FLAT"))
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.service.calculation === "NIGHT"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.NIGHT"))
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.service.calculation === "WEEK"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.WEEK"))
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.service.calculation === "MONTH"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.MONTH"))
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.service.calculation === "USAGE"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.voMsg("tpl.service.USAGE"))
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end price" }, [
                            price.value
                              ? _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v(_vm._s(_vm.getSumPrice(price.value)))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            sg.service.calculation === "USAGE"
                              ? _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v(_vm._s(_vm.voMsg("tpl.service.USAGE")))
                                ])
                              : _vm._e()
                          ])
                        ])
                      : _vm._e()
                  })
                ],
                2
              )
            })
          ],
          2
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "hint" }, [
      _c("sup", [_vm._v("*")]),
      _vm._v(_vm._s(_vm.voMsg("tpl.text.priceObligatory")))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }